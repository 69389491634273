// import React, { useEffect, useState } from "react";
// import { Box, Divider, Flex, Pagination, ScrollArea, Text } from "@mantine/core";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// export default function ApprovedRequest({ searchQuery }) {
//   const navigate = useNavigate();
//   const [requestsData, setRequestsData] = useState([]);
//   const itemsPerPage = 15;
//   const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     const fetchData = () => {
//       axios
//         .get(
//           "https://d13d04nxcwaid6.cloudfront.net/api/v1/admin/devices/approved-history"
//         )
//         .then((response) => {
//           const fetchedData = response.data.map((item) => ({
//             category: item.details.categoryName,
//             platform: item.details.platformName,
//             deviceName: item.details.deviceName,
//             assigneeName: item.userId,
//             date: new Date(item.date).toLocaleDateString(),
//             status: item.action === "booked" ? "Book" : "Return",
//           }));
//           setRequestsData(fetchedData.reverse());
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//         });
//     };

//     const delay = 800;
//     const timer = setTimeout(fetchData, delay);

//     return () => clearTimeout(timer);
//   }, []);
//   const totalItems = requestsData.length;
//   const totalPages = Math.ceil(totalItems / itemsPerPage);
//   const currentItems = requestsData.slice(
//     (currentPage - 1) * itemsPerPage,
//     currentPage * itemsPerPage
//   );

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const filteredData = currentItems.filter((request) =>
//     Object.values(request)
//       .join(" ")
//       .toLowerCase()
//       .includes(searchQuery.toLowerCase())
//   );
//   return (
//     <div>
//       <Box
//         m="10px"
//         p="10px"
//         mr="sm"
//         ml="sm"
//         bg="#182452"
//         style={{
//           border: "1px solid #e0e0e0",
//           borderRadius: "4px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//         }}
//       >
//         <Flex>
//           <Text w={"20%"} fw={"bold"} c="#FFFFFF">
//             Category
//           </Text>
//           <Text w={"20%"} fw={"bold"} c="#FFFFFF">
//             Platform
//           </Text>
//           <Text w={"20%"} fw={"bold"} c="#FFFFFF">
//             Device Name
//           </Text>
//           <Text w={"20%"} fw={"bold"} c="#FFFFFF">
//             Assignee Name
//           </Text>
//           <Text w={"10%"} fw={"bold"} c="#FFFFFF">
//             Approved Date
//           </Text>
//           <Text w={"10%"} fw={"bold"} c="#FFFFFF">
//             Approved For
//           </Text>
//         </Flex>
//       </Box>
//       <Divider my="sm" />
//       <ScrollArea style={{ height: "calc(100vh - 310px)" }}>
//         {filteredData.map((request, index) => (
//           <Box
//             key={index}
//             style={{
//               border: "1px solid #e0e0e0",
//               borderRadius: "4px",
//               cursor: "pointer",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             m="10px"
//             p="10px"
//             mr="sm"
//             ml="sm"
//             className="DeviceList"
//             // onClick={() => navigate("/Requests/ViewRequest")}
//           >
//             <Flex>
//               <Text w={"20%"} c="#393939BA" fw={"bold"}>
//                 {request.category}
//               </Text>
//               <Text w={"20%"} c="#393939BA" fw={"bold"}>
//                 {request.platform}
//               </Text>
//               <Text w={"20%"} c="#393939BA" fw={"bold"}>
//                 {request.deviceName}
//               </Text>
//               <Text w={"20%"} c="#393939BA" fw={"bold"}>
//                 {request.assigneeName}
//               </Text>
//               <Text w={"10%"} c="#393939BA" fw={"bold"}>
//                 {request.date}
//               </Text>
//               <Text w={"10%"} c="#393939BA" fw={"bold"}>
//                 {request.status}
//               </Text>
//             </Flex>
//           </Box>
//         ))}
//         <Flex justify="center" mt="lg">
//           <Pagination
//             page={currentPage}
//             onChange={handlePageChange}
//             total={totalPages}
//             color="#182452"
//           />
//         </Flex>
//       </ScrollArea>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Flex,
  Pagination,
  ScrollArea,
  Text,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function ApprovedRequest({ searchQuery }) {
  const navigate = useNavigate();
  const [requestsData, setRequestsData] = useState([]);
  const itemsPerPage = 15;
  const [currentPage, setCurrentPage] = useState(1);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${API_BASE_URL}/devices/approved-history`
        )
        .then((response) => {
          const fetchedData = response.data.map((item) => {
            const bookHistory = item.details.bookHistory;
            const userEntry = bookHistory.find(
              (entry) => entry.userid === item.userId
            );
            // const assigneeName = userEntry
            //   ? `${userEntry.First_Name} ${userEntry.Last_Name}`
            //   : item.userId;
            const assigneeName = userEntry 
            ? userEntry.name // Use the `name` property directly
            : item.userId; // Fallback to `item.userId` if `userEntry` is null or undefined
          
            return {
              category: item.details.categoryName,
              platform: item.details.platformName,
              deviceName: item.details.deviceName,
              assigneeName: assigneeName,
              date: new Date(item.date).toLocaleDateString(),
              status: item.action === "booked" ? "Book" : "Return",
            };
          });
          setRequestsData(fetchedData.reverse());
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    const delay = 800;
    const timer = setTimeout(fetchData, delay);

    return () => clearTimeout(timer);
  }, []);

  const totalItems = requestsData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = requestsData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredData = currentItems.filter((request) =>
    Object.values(request)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Box
        m="10px"
        p="10px"
        mr="sm"
        ml="sm"
        bg="#182452"
        style={{
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Flex>
          <Text w={"19%"} fw={"bold"} c="#FFFFFF" className="text">
            Category
          </Text>
          <Text w={"19%"} fw={"bold"} c="#FFFFFF" className="text">
            Platform
          </Text>
          <Text w={"19%"} fw={"bold"} c="#FFFFFF" className="text">
            Device Name
          </Text>
          <Text w={"19%"} fw={"bold"} c="#FFFFFF" className="text">
            Assignee Name
          </Text>
          <Text w={"12%"} fw={"bold"} c="#FFFFFF" className="text">
            Approved Date
          </Text>
          <Text w={"12%"} fw={"bold"} c="#FFFFFF" className="text">
            Approved For
          </Text>
        </Flex>
      </Box>
      <Divider my="sm" />
      <ScrollArea style={{ height: "calc(100vh - 310px)" }}>
        {filteredData.map((request, index) => (
          <Box
            key={index}
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "4px",
              cursor: "pointer",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            m="10px"
            p="10px"
            mr="sm"
            ml="sm"
            className="DeviceList"
            // onClick={() => navigate("/Requests/ViewRequest")}
          >
            <Flex>
              <Text w={"19%"} c="#393939BA" fw={"bold"} className="text">
                {request.category}
              </Text>
              <Text w={"19%"} c="#393939BA" fw={"bold"} className="text">
                {request.platform}
              </Text>
              <Text w={"19%"} c="#393939BA" fw={"bold"} className="text">
                {request.deviceName}
              </Text>
              <Text w={"19%"} c="#393939BA" fw={"bold"} className="text">
                {request.assigneeName}
              </Text>
              <Text w={"12%"} c="#393939BA" fw={"bold"} className="text">
                {request.date}
              </Text>
              <Text w={"12%"} c="#393939BA" fw={"bold"} className="text">
                {request.status}
              </Text>
            </Flex>
          </Box>
        ))}
        <Flex justify="center" mt="lg">
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            total={totalPages}
            color="#182452"
          />
        </Flex>
      </ScrollArea>
    </div>
  );
}
