import { useEffect, useState, useRef } from "react";
import { Button, Flex, Input, Select, Text, Box } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import axios from "axios";
import QrcodeGenerator from "./ORcodeGenerator";
import { useAuth } from "../../context/AuthContext";

export default function AddDeviceModal() {
  const { user } = useAuth();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [deviceNameOptions, setDeviceNameOptions] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [category, setCategory] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [platform, setPlatform] = useState("");
  const [serialNum, setSerialNum] = useState("");
  const [imei1, setImei1] = useState("");
  const [imei2, setImei2] = useState("");
  const [processor, setProcessor] = useState("");
  const [generation, setGeneration] = useState("");
  const [screenSize, setScreenSize] = useState("");
  const [ram, setRam] = useState("");
  const [storage, setStorage] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [assetOwner, setAssetOwner] = useState("");
  const [ownershipType, setOwnershipType] = useState("Owned");
  const [cost, setCost] = useState("");
  const [combinedString, setCombinedString] = useState(null);
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const qrCodeRef = useRef(null);
  const [addButton, setAddButton] = useState(true);
  const [assetOwnerOptions, setAssetOwnerOptions] = useState([]);

  useEffect(() => {
    // Fetch categories
    axios
      .get(`${API_BASE_URL}/category/get`)
      .then((response) => {
        const categories = response.data.map((item) => item.categoryName);
        setCategoryOptions(categories);
        if (categories.length > 0) {
          setCategory(categories[0]); // Set default category
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, [API_BASE_URL]);

  const resetForm = () => {
    setCategory("");
    setDeviceName("");
    setPlatform("");
    setSerialNum("");
    setImei1("");
    setImei2("");
    setProcessor("");
    setGeneration("");
    setScreenSize("");
    setRam("");
    setStorage("");
    setMacAddress("");
    setAssetOwner("");
    setOwnershipType("Owned");
    setCost("");
    setCombinedString(null);
    setAddButton(true); // Re-enable the Add button
  };
  useEffect(() => {
    // Fetch platforms based on selected category
    if (category) {
      axios
        .get(`${API_BASE_URL}/platform/${category}`)
        .then((response) => {
          const platforms = response.data.map((item) => item.platformName);
          setPlatformOptions(platforms);
          if (platforms.length > 0) {
            setPlatform(platforms[0]); // Set default platform
          }
        })
        .catch((error) => {
          console.error("Error fetching platforms:", error);
        });
    }
  }, [category, API_BASE_URL]);

  useEffect(() => {
    // Fetch device names based on selected category and platform
    if (category && platform) {
      axios
        .get(`${API_BASE_URL}/devicename/get/cat_pat`, {
          params: {
            categoryName: category,
            platformName: platform,
          },
        })
        .then((response) => {
          const deviceNames = response.data.map((item) => item.deviceName);
          setDeviceNameOptions(deviceNames);
          if (deviceNames.length > 0) {
            setDeviceName(deviceNames[0]); // Set default device name
          }
        })
        .catch((error) => {
          console.error("Error fetching device names:", error);
        });
    }
  }, [category, platform, API_BASE_URL]);

  useEffect(() => {
    const fetchAssetOwners = () => {
      axios
        .get(`${API_BASE_URL}/assetowner/all`)
        .then((response) => {
          const owners = response.data.map((item) => ({
            value: item.ownerName,
            label: item.ownerName,
          }));
          setAssetOwnerOptions(owners);
        })
        .catch((error) => {
          console.error("Error fetching asset owners:", error);
        });
    };

    const delay = setTimeout(fetchAssetOwners, 500);

    return () => clearTimeout(delay);
  }, []);

  const handleSubmit = () => {
    let errors = {};

    if (!serialNum) {
      errors.serialNum = "Serial Number is required.";
    }
    if (!screenSize) {
      errors.screenSize = "Screen Size is required.";
    }
    if (!ram) {
      errors.ram = "RAM is required.";
    }
    if (!storage) {
      errors.storage = "Storage Capacity is required.";
    }
    if (!macAddress) {
      errors.macAddress = "Mac Address is required.";
    }
    if (!assetOwner) {
      errors.assetOwner = "Asset Owner is required.";
    }
    if (!ownershipType) {
      errors.ownershipType = "Ownership Type is required.";
    }
    if (!cost) {
      errors.cost = "Cost is required.";
    }

    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    if (error) {
      // Prevent form submission if there's an error
      return;
    }

    handleGenerate();
  };
  const handleGenerate = () => {
    // Handle generating QR code and adding device logic

    const postData = {
      categoryName: category,
      platformName: platform,
      deviceName: deviceName,
      status: "available",
      did: serialNum,
      imei1: imei1,
      imei2: imei2,
      processor: processor,
      genration: generation,
      screenSize: screenSize,
      ram: ram,
      storage: storage,
      macAddress: macAddress,
      ownerName: assetOwner,
      ownershipType: ownershipType,
      cost: cost,
      added_by: user.Data.email,
    };

    axios
      .post(`${API_BASE_URL}/devices/add`, postData)
      .then((response) => {
        console.log("Device added successfully:", response.data);
        if (response.data === "Device Created Successfully") {
          setCombinedString(serialNum);
          setAddButton(false);
        } else if (response.data === "Device already exists") {
          window.alert(
            "Device already exists. Please choose a different Device ID."
          );
        }
      })
      .catch((error) => {
        console.error("Error adding device:", error);
      });
  };

  const handlePrint = () => {
    // Handle printing QR code logic
    const printWindow = window.open("", "", "width=600,height=400");
    printWindow.document.write(qrCodeRef.current.innerHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const postData = {
    // other fields
    ownerName: assetOwner,
  };

  return (
    <div>
      <Text w="100%" align="center" fw="bold" c="#3E3E3E" size="18px">
        Adding Device
      </Text>
      <Flex direction="column">
        <Flex justify="space-around">
          <Select
            label="Select Category"
            data={categoryOptions}
            value={category}
            rightSection={<IconChevronDown />}
            labelProps={{
              c: "#6D6D6D",
              fw: "bold",
            }}
            w="30%"
            onChange={setCategory}
            allowDeselect={false}
          />
          <Select
            label="Platform"
            data={platformOptions}
            value={platform}
            w="30%"
            rightSection={<IconChevronDown />}
            labelProps={{
              c: "#6D6D6D",
              fw: "bold",
            }}
            allowDeselect={false}
            onChange={setPlatform}
          />
          <Select
            label="Device Name"
            data={deviceNameOptions}
            value={deviceName}
            w="30%"
            rightSection={<IconChevronDown />}
            labelProps={{
              c: "#6D6D6D",
              fw: "bold",
            }}
            allowDeselect={false}
            onChange={setDeviceName}
          />
        </Flex>

        <Flex align="center" justify="space-around" mt="lg">
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Serial No <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={serialNum}
              onChange={(event) => setSerialNum(event.target.value)}
              error={error}
            />
            {fieldErrors.serialNum && (
              <Text c="red" size="12px">
                {fieldErrors.serialNum}
              </Text>
            )}
          </Box>
          <Box w="30%">
            <Text size="14px" fw="bold" c="#6D6D6D">
              IMEI 1
            </Text>
            <Input
              mt="xs"
              value={imei1}
              onChange={(event) => setImei1(event.target.value)}
            />
          </Box>
          <Box w="30%">
            <Text size="14px" fw="bold" c="#6D6D6D">
              IMEI 2
            </Text>
            <Input
              mt="xs"
              value={imei2}
              onChange={(event) => setImei2(event.target.value)}
            />
          </Box>
        </Flex>
        <Flex align="center" justify="space-around" mt="lg">
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Processor Name
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={processor}
              onChange={(event) => setProcessor(event.target.value)}
            />
          </Box>
          <Box w="30%">
            <Text size="14px" fw="bold" c="#6D6D6D">
              Generation
            </Text>
            <Input
              mt="xs"
              value={generation}
              onChange={(event) => setGeneration(event.target.value)}
            />
          </Box>
          <Box w="30%">
            {/* Device Description */}
            <Text size="14px" fw="bold" c="#6D6D6D">
              Screen Size <span style={{ color: "red" }}>*</span>
            </Text>
            <Input
              mt="xs"
              value={screenSize}
              onChange={(event) => setScreenSize(event.target.value)}
            />
            {fieldErrors.screenSize && (
              <Text c="red" size="12px">
                {fieldErrors.screenSize}
              </Text>
            )}
          </Box>
        </Flex>
        <Flex align="center" justify="space-around" mt="lg">
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                RAM <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={ram}
              onChange={(event) => setRam(event.target.value)}
            />
            {fieldErrors.ram && (
              <Text c="red" size="12px">
                {fieldErrors.ram}
              </Text>
            )}
          </Box>
          <Box w="30%">
            <Text size="14px" fw="bold" c="#6D6D6D">
              Storage Capacity <span style={{ color: "red" }}>*</span>
            </Text>
            <Input
              mt="xs"
              value={storage}
              onChange={(event) => setStorage(event.target.value)}
            />
            {fieldErrors.storage && (
              <Text c="red" size="12px">
                {fieldErrors.storage}
              </Text>
            )}
          </Box>
          <Box w="30%">
            <Text size="14px" fw="bold" c="#6D6D6D">
              Mac Address <span style={{ color: "red" }}>*</span>
            </Text>
            <Input
              mt="xs"
              value={macAddress}
              onChange={(event) => setMacAddress(event.target.value)}
            />
            {fieldErrors.macAddress && (
              <Text c="red" size="12px">
                {fieldErrors.macAddress}
              </Text>
            )}
          </Box>
        </Flex>
        <Flex align="center" justify="space-around" mt="lg">
          <Box w="30%">
            <Text size="14px" fw="bold" c="#6D6D6D">
              Asset Owner <span style={{ color: "red" }}>*</span>
            </Text>
            <Select
              mt="xs"
              data={assetOwnerOptions}
              value={assetOwner}
              onChange={setAssetOwner}
              required
              error={fieldErrors.assetOwner}
              allowDeselect={false}
              rightSection={<IconChevronDown />}
            />
          </Box>
          <Box w="30%">
            {/* Device Description */}
            <Text size="14px" fw="bold" c="#6D6D6D">
              Ownership Type <span style={{ color: "red" }}>*</span>
            </Text>
            {/* <Input
              mt="xs"
              value={ownershipType}
              onChange={(event) => setOwnershipType(event.target.value)}
            />
            {fieldErrors.ownershipType && (
              <Text c="red" size="12px">
                {fieldErrors.ownershipType}
              </Text>
            )} */}
            <Select
              mt="xs"
              data={["Owned", "Rented"]}
              defaultValue="Owned"
              onChange={setOwnershipType}
              required
              error={fieldErrors.ownershipType}
              allowDeselect={false}
              rightSection={<IconChevronDown />}
            />
          </Box>
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Cost <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={cost}
              onChange={(event) => setCost(event.target.value)}
            />
            {fieldErrors.cost && (
              <Text c="red" size="12px">
                {fieldErrors.cost}
              </Text>
            )}
          </Box>
        </Flex>

        <Box align="center">
          <Button
            mt="lg"
            bg="#182452"
            onClick={handleSubmit}
            w="30%"
            disabled={!addButton}
          >
            Add Device & Generate QR Code
          </Button>
        </Box>

        <Box align="center">
          <Button
            mt="lg"
            bg="#182452"
            w="15%"
            onClick={resetForm}
            disabled={addButton}
          >
            Reset
          </Button>
        </Box>
        <Box align="center" mt="md">
          <Box
            style={{
              boxShadow: "0 4px 18px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              border: "1px solid #8080805E",
              padding: 10,
              alignItems: "center",
            }}
            w="20%"
            ref={qrCodeRef}
          >
            <QrcodeGenerator value={combinedString} />
          </Box>
          {/* Button to print QR code */}
          <Button
            mt="md"
            bg="#182452"
            onClick={handlePrint}
            disabled={!combinedString}
          >
            Print
          </Button>
        </Box>
      </Flex>
    </div>
  );
}
