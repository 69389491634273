import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  Paper,
  Text,
  Modal,
  ScrollArea,
} from "@mantine/core";
import QRcodeScanner from "./QRcodeScanner";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export default function ReturnScanner() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const {
    category,
    deviceName,
    platform,
    startDate,
    returnDate,
    device,
    did,
    projectName,
  } = location.state;
  const [currentDate, setCurrentDate] = useState(getDate());
  const [showModal, setShowModal] = useState(false);
  const [apiSucess, setApiSuceess] = useState(null);
  const [inputData, setInputData] = useState(null);

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date} / ${month} / ${year}`;
  }

  function handleReturnScan(scannedData) {
    const apiUrl = `${API_BASE_URL}/devices/request-return`;
    // const userId = "suhas.bhat@igsindia.net";
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        did: scannedData,
        userId: user.Data.email,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("API Response:", data);
        setShowModal(true);
        // setModalMessage(data.message || "Unknown response");
        setApiSuceess(true);
      })
      .catch((error) => {
        console.error("Error returning device:", error);
        setShowModal(true);
        // setModalMessage("Error returning device");
        setApiSuceess(false);
      });
  }

  const bookDeviceInput = async (inputData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/devices/request-return`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          did: inputData,
          userId: user.Data.email,
        }),
      });
      if (response.ok) {
        setShowModal(true);
        setApiSuceess(true);
      } else {
        setShowModal(true);
        setApiSuceess(false);
        console.error("Error booking the device");
      }
    } catch (error) {
      setShowModal(false);
      console.error("Error booking the device:", error);
    }
  };
  return (
    <div>
      <ScrollArea h="calc(100vh - 50px)">
        <Paper bg="#FFFFF" m="md" pb="xl">
          <Flex justify="center" align="center" p="md">
            <Box w="65%" align="center">
              <Text c="#DC8585" size="xl" fs="italic" fw="bold">
                Scan your Device's QR Code to Return
              </Text>
              <Box w="55%" mt="md">
                <QRcodeScanner onScan={handleReturnScan} />
                <Text pt="xl" c="#00000080" fw="bold" size="20px">
                  OR
                </Text>
              </Box>
            </Box>
            <Box w="35%">
              <Box
                p="30px"
                bg="#EFEFEF"
                style={{
                  boxShadow: "0 4px 18px rgba(0, 0, 0, 0.1)",
                  borderRadius: "5px",
                  border: "1px solid #8080805E",
                }}
              >
                <Box>
                  <Box>
                    <Text c="#00000080" fw="bold">
                      Category
                    </Text>
                    <Box
                      w="100%"
                      bg="#FFFFFF"
                      py="5px"
                      px="10px"
                      style={{ border: "1px solid #8080805E", borderRadius: 5 }}
                    >
                      <Text>{category}</Text>
                    </Box>
                  </Box>
                  <Box mt="md">
                    <Text c="#00000080" fw="bold">
                      Device Name
                    </Text>
                    <Box
                      w="100%"
                      bg="#FFFFFF"
                      py="5px"
                      px="10px"
                      style={{ border: "1px solid #8080805E", borderRadius: 5 }}
                    >
                      <Text>{deviceName}</Text>
                    </Box>
                  </Box>
                  <Box mt="md">
                    <Text c="#00000080" fw="bold">
                      Platform
                    </Text>
                    <Box
                      w="100%"
                      bg="#FFFFFF"
                      py="5px"
                      px="10px"
                      style={{ border: "1px solid #8080805E", borderRadius: 5 }}
                    >
                      <Text>{platform}</Text>
                    </Box>
                  </Box>
                  <Box mt="md">
                    <Text c="#00000080" fw="bold">
                      Start Date
                    </Text>
                    <Box
                      w="100%"
                      bg="#FFFFFF"
                      py="5px"
                      px="10px"
                      style={{ border: "1px solid #8080805E", borderRadius: 5 }}
                    >
                      <Text>{startDate}</Text>
                    </Box>
                  </Box>

                  <Box mt="md">
                    <Text c="#00000080" fw="bold">
                      Return Date
                    </Text>
                    <Box
                      w="100%"
                      bg="#FFFFFF"
                      py="5px"
                      px="10px"
                      style={{ border: "1px solid #8080805E", borderRadius: 5 }}
                    >
                      <Text>{currentDate}</Text>
                    </Box>
                  </Box>

                  <Box mt="md">
                    <Text c="#00000080" fw="bold">
                      Device ID
                    </Text>
                    <Box
                      w="100%"
                      bg="#FFFFFF"
                      py="5px"
                      px="10px"
                      style={{ border: "1px solid #8080805E", borderRadius: 5 }}
                    >
                      <Text>{did}</Text>
                    </Box>
                  </Box>

                  <Box mt="md">
                    <Text c="#00000080" fw="bold">
                      Project Name
                    </Text>
                    <Box
                      w="100%"
                      bg="#FFFFFF"
                      py="5px"
                      px="10px"
                      style={{ border: "1px solid #8080805E", borderRadius: 5 }}
                    >
                      <Text>{projectName}</Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Flex>
          <Flex justify="space-between" mt="xl">
            <Box w="65%" align="center">
              <Text c="#00000080" fw="bold" size="18px">
                Enter Device ID
              </Text>
              <Box
                w="50%"
                mt="md"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #8080805E",
                  overflow: "hidden",
                  borderRadius: "5px",
                }}
              >
                <Input
                  variant="filled"
                  onChange={(e) => setInputData(e.target.value)}
                />
              </Box>
            </Box>
            <Flex align="end" gap="xl" pr={"xl"}>
              <Button bg="red" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button bg="#182452" onClick={() => bookDeviceInput(inputData)}>
                Return
              </Button>
            </Flex>
          </Flex>
        </Paper>
        {/* Modal for showing success or error message */}
        <Modal opened={showModal} onClose={() => setShowModal(false)} centered>
          {apiSucess ? (
            <Box align="center">
              <Text c="#00000080" fw="bold" size="20px">
                Returned Requested!
              </Text>
              <Button onClick={() => navigate(-1)} mt="md" bg="#182452">
                OK
              </Button>
            </Box>
          ) : (
            <Box align="center">
              <Text c="#00000080" fw="bold" size="20px">
                Device Not Matched
              </Text>
              <Flex gap="xl" align="center" justify="center" mt="lg">
                <Button onClick={() => navigate(-1)} bg="red">
                  Cancel
                </Button>
                <Button onClick={() => setShowModal(false)} bg="#182452">
                  Retry
                </Button>
              </Flex>
            </Box>
          )}
        </Modal>
      </ScrollArea>
    </div>
  );
}
