// import React, { useState, useEffect } from "react";
// import {
//   Select,
//   Box,
//   Text,
//   Flex,
//   Paper,
//   Button,
//   Modal,
//   ScrollArea,
//   Loader,
// } from "@mantine/core";
// import { useNavigate } from "react-router-dom";
// import { IconChevronDown, IconSearch } from "@tabler/icons-react";
// import AddDeviceModal from "../../components/pagesComponent/AddDeviceModal";
// import { useDisclosure } from "@mantine/hooks";

// const DeviceManagement = () => {
//   const [devicesData, setDevicesData] = useState({ categories: {} });
//   const [loading, setLoading] = useState(true);
//   const [opened, { open, close }] = useDisclosure(false);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedPlatform, setSelectedPlatform] = useState("");
//   const [selectedDevice, setSelectedDevice] = useState("");
//   const navigate = useNavigate();
//   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

//   useEffect(() => {
//     setLoading(true);
//     fetch(`${API_BASE_URL}`)
//       .then((response) => response.json())
//       .then((data) => {
//         setDevicesData({ categories: data });
//         const initialCategory = Object.keys(data)[0];
//         const initialPlatform = data[initialCategory]?.[0]?.Platform || "";
//         const initialDeviceName =
//           data[initialCategory]?.[0]?.Devices?.[0]?.DeviceName || "";
//         setSelectedCategory(initialCategory);
//         setSelectedPlatform(initialPlatform);
//         setSelectedDevice(initialDeviceName);
//       })
//       .catch((error) => console.error("Error fetching data:", error))
//       .finally(() => setLoading(false));
//   }, []);

//   useEffect(() => {
//     if (devicesData.categories[selectedCategory]) {
//       setSelectedPlatform(
//         devicesData.categories[selectedCategory][0]?.Platform || ""
//       );
//       setSelectedDevice(
//         devicesData.categories[selectedCategory][0]?.Devices?.[0]?.DeviceName ||
//           ""
//       );
//     }
//   }, [selectedCategory, devicesData]);

//   const handleCategoryChange = (category) => {
//     setSelectedCategory(category);
//   };

//   const handlePlatformChange = (platform) => {
//     setSelectedPlatform(platform);
//     setSelectedDevice(
//       devicesData.categories[selectedCategory]?.find(
//         (c) => c.Platform === platform
//       )?.Devices?.[0]?.DeviceName || ""
//     );
//   };

//   const handleDeviceChange = (device) => {
//     setSelectedDevice(device);
//   };

//   const renderCategoryOptions = () => {
//     return Object.keys(devicesData.categories).map((category) => ({
//       value: category,
//       label: category,
//     }));
//   };

//   const renderPlatformOptions = () => {
//     if (devicesData.categories[selectedCategory]) {
//       return devicesData.categories[selectedCategory].map((category) => ({
//         value: category.Platform,
//         label: category.Platform,
//       }));
//     }
//     return [];
//   };

//   const renderDeviceOptions = () => {
//     const devices =
//       devicesData.categories[selectedCategory]?.find(
//         (c) => c.Platform === selectedPlatform
//       )?.Devices || [];
//     return devices.map((device) => ({
//       value: device.DeviceName,
//       label: device.DeviceName,
//     }));
//   };

//   const renderDeviceList = () => {
//     const selectedDevices =
//       devicesData.categories[selectedCategory]?.find(
//         (c) => c.Platform === selectedPlatform
//       )?.Devices || [];
//     const selectedDeviceList =
//       selectedDevices.find((d) => d.DeviceName === selectedDevice)
//         ?.DeviceList || [];

//     if (selectedDeviceList.length > 0) {
//       return (
//         <Box>
//           {selectedDeviceList.map((device, index) => {
//             const lastHistory = device.bookHistory?.slice(-1)[0];
//             const assigneeName = lastHistory
//               ? `${lastHistory.First_Name} ${lastHistory.Last_Name}`
//               : "N/A";
//             const bookingDate = lastHistory
//               ? new Date(lastHistory.dateofbooking).toLocaleDateString("en-IN")
//               : "N/A";
//             // const returnDate = lastHistory
//             //   ? new Date(lastHistory.dateOfReturn).toLocaleDateString("en-IN")
//             //   : "N/A";

//             return (
//               <Box
//                 key={index}
//                 style={{
//                   border: "1px solid #e0e0e0",
//                   borderRadius: "4px",
//                   cursor: "pointer",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 m="10px"
//                 mr="sm"
//                 ml="sm"
//                 className="DeviceList"
//                 bg="#FFFFFF"
//                 onClick={() =>
//                   navigate(`/device-master/device-history/${device.did}`)
//                 }
//               >
//                 <Flex justify="space-between">
//                   <Box w={"28%"}>
//                     <Text
//                       c="#FFFFFF"
//                       fw="bold"
//                       bg={device.status === "available" ? "green" : "red"}
//                       p="10px"
//                       w="100%"
//                     >
//                       {device.did}
//                     </Text>
//                   </Box>
//                   {device.status !== "available" && lastHistory && (
//                     <Flex w="52%" align="center">
//                       <Box w="50%">
//                         <Text p="10px" fw={"600"} c="#6D6D6D">
//                           {assigneeName}
//                         </Text>
//                       </Box>
//                       <Box w="50%">
//                         <Text p="10px" fw={"600"} c="#6D6D6D">
//                           {bookingDate}
//                         </Text>
//                       </Box>
//                       {/* <Text>Return Date: {returnDate}</Text> */}
//                     </Flex>
//                   )}
//                   {device.status === "available" && (
//                     <Flex w="52%" align="center">
//                       <Box w="50%">
//                         <Text p="10px" fw={"600"} c="#6D6D6D">
//                           -----
//                         </Text>
//                       </Box>
//                       <Box w="50%">
//                         <Text p="10px" fw={"600"} c="#6D6D6D">
//                           -------{" "}
//                         </Text>
//                       </Box>
//                     </Flex>
//                   )}
//                   <Text w={"20%"} p="10px" fw={"600"} c="#6D6D6D">
//                     <span
//                       style={{
//                         color: device.status === "available" ? "green" : "red",
//                       }}
//                     >
//                       {device.status}
//                     </span>
//                   </Text>
//                 </Flex>
//               </Box>
//             );
//           })}
//         </Box>
//       );
//     }
//     return <Text>No devices found for the selected criteria.</Text>;
//   };

//   return (
//     <Box>
//       <Flex justify="space-between" align="center" mr="50px" ml="md">
//         <Text c={"#393939BA"} fw={"bold"} size="20px">
//           Device Master
//         </Text>
//         {/* <Select
//           placeholder="Search Device"
//           searchable
//           rightSection={<IconSearch />}
//         /> */}
//       </Flex>
//       <Paper
//         bg="#FFFFFF"
//         mt="lg"
//         mr="sm"
//         ml="sm"
//         p="lg"
//         style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
//       >
//         <Flex align="center" mb="lg">
//           <Flex w="50%" justify="space-around" gap="md">
//             <Select
//               value={selectedCategory}
//               placeholder="Select Category"
//               onChange={(value) => handleCategoryChange(value)}
//               data={renderCategoryOptions()}
//               allowDeselect={false}
//               rightSection={<IconChevronDown />}
//             />

//             <Select
//               value={selectedPlatform}
//               placeholder="Select Platform"
//               onChange={(value) => handlePlatformChange(value)}
//               data={renderPlatformOptions()}
//               allowDeselect={false}
//               rightSection={<IconChevronDown />}
//             />

//             <Select
//               value={selectedDevice}
//               placeholder="Select Device"
//               onChange={(value) => handleDeviceChange(value)}
//               data={renderDeviceOptions()}
//               allowDeselect={false}
//               rightSection={<IconChevronDown />}
//             />
//           </Flex>
//           <Flex w="50%" justify="flex-end" gap="md" pr="xl">
//             <Button
//               bg="#182452"
//               onClick={() => navigate("/device-master/add-category")}
//             >
//               Add Categories +
//             </Button>
//             <Button onClick={open} bg="#182452">
//               Add Device Details +
//             </Button>
//           </Flex>
//         </Flex>
//         <Box
//           m="10px"
//           p="10px"
//           bg="#182452"
//           style={{
//             border: "1px solid #e0e0e0",
//             borderRadius: "4px",
//             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           }}
//         >
//           <Flex>
//             <Text w={"28%"} fw={"bold"} c="#FFFFFF">
//               Device ID
//             </Text>
//             <Text w={"26%"} fw={"bold"} c="#FFFFFF" px="5px">
//               Assignee Name
//             </Text>
//             <Text w={"26%"} fw={"bold"} c="#FFFFFF" px="5px">
//               Date of Booking
//             </Text>
//             <Text w={"20%"} fw={"bold"} c="#FFFFFF" px="5px">
//               Status
//             </Text>
//           </Flex>
//         </Box>
//         <ScrollArea style={{ height: "calc(100vh - 295px)" }} pr="sm">
//           {loading ? (
//             <Flex
//               justify="center"
//               align="center"
//               style={{ height: "calc(100vh - 150px)" }}
//             >
//               <Loader size={50} color="#182452" />
//             </Flex>
//           ) : (
//             renderDeviceList()
//           )}
//         </ScrollArea>
//       </Paper>
//       <Modal opened={opened} onClose={close} centered size="75%">
//         <AddDeviceModal />
//       </Modal>
//     </Box>
//   );
// };

// export default DeviceManagement;

import React, { useState, useEffect } from "react";
import {
  Select,
  Box,
  Text,
  Flex,
  Paper,
  Button,
  Modal,
  ScrollArea,
  Loader,
  Pagination,
  TextInput,
  Input,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import AddDeviceModal from "../../components/pagesComponent/AddDeviceModal";
import { useDisclosure } from "@mantine/hooks";
import igsLogo from "../../asset/igsLogo.png";
import styles from "../book-device/BookDevice.module.css"; 

const DeviceManagement = () => {
  const [devicesData, setDevicesData] = useState({ categories: {} });
  const [loading, setLoading] = useState(true);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage, setDevicesPerPage] = useState(10);
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    setLoading(true);
    fetch(`${API_BASE_URL}`)
      .then((response) => response.json())
      .then((data) => {
        setDevicesData({ categories: data });
        const initialCategory = Object.keys(data)[0];
        const initialPlatform = data[initialCategory]?.[0]?.Platform || "";
        const initialDeviceName =
          data[initialCategory]?.[0]?.Devices?.[0]?.DeviceName || "";
        setSelectedCategory(initialCategory);
        setSelectedPlatform(initialPlatform);
        setSelectedDevice(initialDeviceName);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (devicesData.categories[selectedCategory]) {
      setSelectedPlatform(
        devicesData.categories[selectedCategory][0]?.Platform || ""
      );
      setSelectedDevice(
        devicesData.categories[selectedCategory][0]?.Devices?.[0]?.DeviceName ||
          ""
      );
    }
  }, [selectedCategory, devicesData]);

  const handleCategoryChange = (category) => {
    console.log("Selected Category:", category); // Debug the selected category
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to first page on category change
  };

  const handlePlatformChange = (platform) => {
    setSelectedPlatform(platform);
    setCurrentPage(1); // Reset to first page on platform change
    setSelectedDevice(
      devicesData.categories[selectedCategory]?.find(
        (c) => c.Platform === platform
      )?.Devices?.[0]?.DeviceName || ""
    );
  };

  const handleDeviceChange = (device) => {
    setSelectedDevice(device);
    setCurrentPage(1); // Reset to first page on device change
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.currentTarget.value);
    setCurrentPage(1); // Reset to first page on search query change
  };

  const renderCategoryOptions = () => {
    return Object.keys(devicesData.categories).map((category) => ({
      value: String(category), // Ensure all values are strings
      label: String(category),
    }));
  };

  const renderPlatformOptions = () => {
    if (devicesData.categories[selectedCategory]) {
      return devicesData.categories[selectedCategory].map((category) => ({
        value: String(category.Platform), // Ensure value is a string
        label: String(category.Platform),
      }));
    }
    return [];
  };

  const renderDeviceOptions = () => {
    const devices =
      devicesData.categories[selectedCategory]?.find(
        (c) => c.Platform === selectedPlatform
      )?.Devices || [];
    return devices.map((device) => ({
      value: String(device.DeviceName), // Ensure value is a string
      label: String(device.DeviceName),
    }));
  };

  const renderDeviceList = () => {
    console.log("Selected Category:", selectedCategory);
    console.log("Selected Devices Data:", devicesData.categories[selectedCategory]);
  
    const selectedDevices =
      devicesData.categories[selectedCategory]?.find(
        (c) => c.Platform === selectedPlatform
      )?.Devices || [];
    const selectedDeviceList =
      selectedDevices.find((d) => d.DeviceName === selectedDevice)
        ?.DeviceList || [];

    const filteredDevices = selectedDeviceList.filter((device) =>
      Object.values(device)
        .join(" ")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );

    const indexOfLastDevice = currentPage * devicesPerPage;
    const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
    const currentDevices = filteredDevices.slice(
      indexOfFirstDevice,
      indexOfLastDevice
    );
 
    if (filteredDevices.length > 0) {
      return (
        <Box>
          {currentDevices.map((device, index) => {
            const lastHistory = device.bookHistory?.slice(-1)[0];
            const assigneeName = lastHistory
              ? lastHistory.name
              : "N/A";
            const bookingDate = lastHistory
              ? new Date(lastHistory.dateofbooking).toLocaleDateString("en-IN")
              : "N/A";

            return (
              <Box
                key={index}
                style={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  cursor: "pointer",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                m="10px"
                mr="sm"
                ml="sm"
                className="DeviceList"
                bg="#FFFFFF"
                onClick={() =>
                  navigate(`/device-master/device-history/${device.did}`)
                }
              >
                <Flex justify="space-between">
                  <Box w={"28%"}>
                    <Text
                      c="#FFFFFF"
                      fw="bold"
                      bg={device.status === "available" ? "green" : "red"}
                      p="10px"
                      w="100%"
                    >
                      {device.did}
                    </Text>
                  </Box>
                  {device.status !== "available" && lastHistory && (
                    <Flex w="52%" align="center">
                      <Box w="50%">
                        <Text p="10px" fw={"600"} c="#6D6D6D">
                          {assigneeName}
                        </Text>
                      </Box>
                      <Box w="50%">
                        <Text p="10px" fw={"600"} c="#6D6D6D">
                          {bookingDate === "Invalid Date"
                            ? "-----"
                            : bookingDate}
                        </Text>
                      </Box>
                    </Flex>
                  )}
                  {device.status === "available" && (
                    <Flex w="52%" align="center">
                      <Box w="50%">
                        <Text p="10px" fw={"600"} c="#6D6D6D">
                          -----
                        </Text>
                      </Box>
                      <Box w="50%">
                        <Text p="10px" fw={"600"} c="#6D6D6D">
                          -------{" "}
                        </Text>
                      </Box>
                    </Flex>
                  )}
                  <Text w={"20%"} p="10px" fw={"600"} c="#6D6D6D">
                    <span
                      style={{
                        color: device.status === "available" ? "green" : "red",
                      }}
                    >
                      {device.status}
                    </span>
                  </Text>
                </Flex>
              </Box>
            );
          })}
        </Box>
      );
    }
    return <Text>No devices found for the selected criteria.</Text>;
  };

  return (
    <Box>


<Flex justify="space-between" align="center" mr="50px" ml="md">
        <Text c={"#393939BA"} fw={"bold"} size="20px">
          Device Master
        </Text>

    
      <Flex align="center" gap="sm">
          <Input
            placeholder="Search Device"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            searchable
            rightSection={<IconSearch />}
          />
          <div className={styles.igsLogo}>
            <img src={igsLogo} alt="igsLogo" />
          </div>
        </Flex>
        </Flex>
      <Paper
        bg="#FFFFFF"
        mt="lg"
        mr="sm"
        ml="sm"
        p="lg"
        style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
      >
        <Flex align="center" mb="lg">
          <Flex w="50%" justify="space-around" gap="md">
            <Select
              value={selectedCategory}
              placeholder="Select Category"
              onChange={(value) => handleCategoryChange(value)}
              data={renderCategoryOptions()}
              allowDeselect={false}
              rightSection={<IconChevronDown />}
            />

            <Select
              value={selectedPlatform}
              placeholder="Select Platform"
              onChange={(value) => handlePlatformChange(value)}
              data={renderPlatformOptions()}
              allowDeselect={false}
              rightSection={<IconChevronDown />}
            />

            <Select
              value={selectedDevice}
              placeholder="Select Device"
              onChange={(value) => handleDeviceChange(value)}
              data={renderDeviceOptions()}
              allowDeselect={false}
              rightSection={<IconChevronDown />}
            />
          </Flex>
          <Flex w="50%" justify="flex-end" gap="md" pr="xl">
            <Button
              bg="#182452"
              onClick={() => navigate("/device-master/add-category")}
            >
              Add Categories +
            </Button>
            <Button onClick={open} bg="#182452">
              Add Device Details +
            </Button>
          </Flex>
        </Flex>
        <Box
          m="10px"
          p="10px"
          bg="#182452"
          style={{
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Flex>
            <Text w={"28%"} fw={"bold"} c="#FFFFFF">
              Device ID
            </Text>
            <Text w={"26%"} fw={"bold"} c="#FFFFFF" px="5px">
              Assignee Name
            </Text>
            <Text w={"26%"} fw={"bold"} c="#FFFFFF" px="5px">
              Date of Booking
            </Text>
            <Text w={"20%"} fw={"bold"} c="#FFFFFF" px="5px">
              Status
            </Text>
          </Flex>
        </Box>
        <ScrollArea style={{ height: "calc(100vh - 270px)" }} pr="sm">
          {loading ? (
            <Flex
              justify="center"
              align="center"
              style={{ height: "calc(100vh - 150px)" }}
            >
              <Loader size={50} color="#182452" />
            </Flex>
          ) : (
            renderDeviceList()
          )}
          <Flex justify="center" mt="lg">
            <Pagination
              page={currentPage}
              color="#182452"
              onChange={setCurrentPage}
              total={Math.ceil(
                (devicesData.categories[selectedCategory]
                  ?.find((c) => c.Platform === selectedPlatform)
                  ?.Devices?.find((d) => d.DeviceName === selectedDevice)
                  ?.DeviceList.filter((device) =>
                    Object.values(device)
                      .join(" ")
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  ).length || 0) / devicesPerPage
              )}
            />
          </Flex>
        </ScrollArea>
      </Paper>
      <Modal opened={opened} onClose={close} centered size="75%">
        <AddDeviceModal />
      </Modal>
    </Box>
  );
};

export default DeviceManagement;
