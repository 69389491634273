import { useEffect, useState } from "react";
import {
  IconSettings,
  IconDeviceIpadHeart,
  IconLogout,
  IconDeviceTablet,
  IconUserCircle,
  IconMessageStar,
  IconLayoutDashboardFilled,
} from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Flex, Image, Text } from "@mantine/core";
import classes from "./NavbarNested.module.css";
import logo from "./../../../asset/logo.png";
import { useAuth } from "../../../context/AuthContext";

export function NavbarSegmented({ role, userName, onLogOut }) {
  const { user } = useAuth();
  console.log(user.Data.role);
  const tabs = {
    account: [
      { link: "/book-device", label: "Book Device", icon: IconDeviceTablet },
      { link: "/return-device", label: "My Device", icon: IconDeviceIpadHeart },
      role === "Admin" && {
        link: "/Dashboard",
        label: "Dashboard",
        icon: IconLayoutDashboardFilled,
      },
      role === "Admin" && {
        link: "/device-master",
        label: "Device Master",
        icon: IconSettings,
      },
      role === "Admin" && {
        link: "/Requests",
        label: "Requests",
        icon: IconMessageStar,
      },
    ].filter(Boolean), // Filter out any falsy values (null, undefined)
  };

  const [active, setActive] = useState("Book Device");
  const navigate = useNavigate();

  const links = tabs.account.map((item) => (
    <Link
      className={classes.link}
      data-active={item.label === active || undefined}
      to={item.link}
      key={item.label}
      onClick={() => setActive(item.label)}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <Text fw="500" size="18px">
        {item.label}
      </Text>
    </Link>
  ));

  return (
    <nav className={classes.navbar}>
      <Flex justify={"space-around"} align={"center"} mb={"lg"} gap="sm">
        {/* <IconRibbonHealth stroke={1.5} /> */}
        <Box
          bg="#FFFFFF"
          style={{ borderRadius: 5, border: "2px solid #0000001F" }}
          p="2px"
        >
          <Image src={logo} h={40} />
        </Box>
        <Box>
          <Text size={"20px"} fw="bold" c="#FFFFFF">
            Asset Manager
          </Text>
          <Text size={"14px"} fw="bold" c="#FFFFFF" mt="5px">
            IGS
          </Text>
        </Box>
      </Flex>
      <div className={classes.navbarMain}>{links}</div>
      <div className={classes.footer}>
        <Flex direction="column" gap="md" pb="lg">
          <Flex gap="md">
            <IconUserCircle color="#FFFFFF" />
            <Text c="#FFFFFF">{userName}</Text>
          </Flex>
          <Flex gap="md" style={{ cursor: "pointer" }} onClick={onLogOut}>
            <IconLogout color="#FFFFFF" />
            <Text c="#FFFFFF">Logout</Text>
          </Flex>
        </Flex>
      </div>
    </nav>
  );
}
