import {
  Box,
  Divider,
  Flex,
  ScrollArea,
  Text,
  Pagination,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function NewRequest({ searchQuery }) {
  const navigate = useNavigate();
  const [requestsData, setRequestsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust the number of items per page as needed
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/devices/pending-requests`
        );
        const data = response.data.map((request) => ({
          category: request.categoryName,
          platform: request.platformName,
          deviceName: request.deviceName,
          // assigneeName: `${request.bookHistory.First_Name} ${request.bookHistory.Last_Name}`,
          assigneeName: request.bookHistory.name,

          date: request.status.includes("booking")
            ? request.bookHistory.dateOfRequest
              ? new Date(request.bookHistory.dateOfRequest).toLocaleDateString()
              : "N/A"
            : request.bookHistory.requestReturnDate
            ? new Date(
                request.bookHistory.requestReturnDate
              ).toLocaleDateString()
            : "N/A",
          status: request.status.includes("booking") ? "Book" : "Return",
          did: request.did,
        }));

        setRequestsData(data);
      } catch (error) {
        console.error("Error fetching requests:", error);
        setError("Error fetching requests");
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const totalItems = requestsData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = requestsData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  const filteredData = currentItems.filter((request) =>
    Object.values(request)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Box
        m="10px"
        p="10px"
        bg="#182452"
        style={{
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Flex>
          <Text w={"18%"} fw={"bold"} c="#FFFFFF" className="text">
            Category
          </Text>
          <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
            Platform
          </Text>
          <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
            Device Name
          </Text>
          <Text w={"17%"} fw={"bold"} c="#FFFFFF" className="text">
            Assignee Name
          </Text>
          <Text w={"12.5%"} fw={"bold"} c="#FFFFFF" className="text">
            Request Date
          </Text>
          <Text w={"12.5%"} fw={"bold"} c="#FFFFFF" className="text">
            Request For
          </Text>
        </Flex>
      </Box>
      <Divider my="sm" />
      <ScrollArea style={{ height: "calc(100vh - 310px)" }}>
        {filteredData.length > 0 ? (
          filteredData.map((request, index) => (
            <Box
              key={index}
              style={{
                border: "1px solid #e0e0e0",
                borderRadius: "4px",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              m="10px"
              p="10px"
              className="DeviceList"
              onClick={() =>
                navigate(`/Requests/ViewRequest/${request.did}`, {
                  state: { request },
                })
              }
            >
              <Flex>
                <Text w={"18%"} c="#393939BA" fw={"bold"} className="text">
                  {request.category}
                </Text>
                <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
                  {request.platform}
                </Text>
                <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
                  {request.deviceName}
                </Text>
                <Text w={"17%"} c="#393939BA" fw={"bold"} className="text">
                  {request.assigneeName}
                </Text>
                <Text w={"12.5%"} c="#393939BA" fw={"bold"} className="text">
                  {request.date}
                </Text>
                <Text w={"12.5%"} c="#393939BA" fw={"bold"} className="text">
                  {request.status}
                </Text>
              </Flex>
            </Box>
          ))
        ) : (
          <Text
            m="10px"
            p="10px"
            c="#393939BA"
            fw={"bold"}
            align="center"
            size="20px"
            mt="50px"
          >
            No pending requests
          </Text>
        )}
        <Flex justify="center" mt="lg">
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            total={totalPages}
            color="#182452"
          />
        </Flex>
      </ScrollArea>
    </div>
  );
}
