import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Text,
  Flex,
  Paper,
  Box,
  ScrollArea,
  Button,
  Input,
  Pagination,
} from "@mantine/core";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { IconSearch } from "@tabler/icons-react";

export default function DeviceHistory() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [deviceUsageHistory, setDeviceUsageHistory] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/devices/get/user/${user.Data.email}`
        );

        const formattedHistory = response.data.deviceUsageHistory
          .filter((historyItem) => historyItem.dateOfReturn !== null)
          .slice()
          .reverse()
          .map((historyItem) => ({
            ...historyItem,
            dateofbooking: new Date(
              historyItem.dateofbooking
            ).toLocaleDateString("en-IN", {
              timeZone: "Asia/Kolkata",
            }),
            dateOfReturn: historyItem.dateOfReturn
              ? new Date(historyItem.dateOfReturn).toLocaleDateString("en-IN", {
                  timeZone: "Asia/Kolkata",
                })
              : "Not returned",
          }));

        setDeviceUsageHistory(formattedHistory);
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };

    fetchDevices();
  }, [API_BASE_URL, user.Data.email]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const filteredHistory = deviceUsageHistory.filter((historyItem) =>
    [
      "deviceName",
      "categoryName",
      "platformName",
      "dateofbooking",
      "dateOfReturn",
    ].some((key) =>
      historyItem[key].toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  const totalItems = filteredHistory.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = filteredHistory.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Flex justify="space-between" align="center" mr="50px" ml="md">
        <Text c={"#393939BA"} fw={"bold"} size="20px" mb="md">
          My Device History
        </Text>
        <Input
          placeholder="Search"
          value={searchValue}
          onChange={handleSearchChange}
          rightSection={<IconSearch />}
        />
      </Flex>
      <Button bg="#182452" onClick={() => navigate(-1)} m="md">
        Back
      </Button>
      <Paper
        bg="#FFFFFF"
        mt="lg"
        mr="sm"
        ml="sm"
        p="lg"
        style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
      >
        <Box
          p="10px"
          bg="#182452"
          style={{
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Flex>
            <Text w={"20%"} fw={"bold"} c="#FFFFFF">
              Category
            </Text>
            <Text w={"20%"} fw={"bold"} c="#FFFFFF">
              Platform
            </Text>
            <Text w={"20%"} fw={"bold"} c="#FFFFFF">
              Device Name
            </Text>
            <Text w={"20%"} fw={"bold"} c="#FFFFFF">
              Date of Booking
            </Text>
            <Text w={"20%"} fw={"bold"} c="#FFFFFF">
              Date of Return
            </Text>
          </Flex>
        </Box>
        <ScrollArea
          style={{ height: "calc(100vh - 280px)" }}
          type="never"
          mt="md"
        >
          {currentItems.map((historyItem, index) => (
            <Box
              key={index}
              style={{
                border: "1px solid #e0e0e0",
                borderRadius: "4px",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              m="10px"
              p="10px"
              className="DeviceList"
            >
              <Flex justify="space-between" align="center">
                <Text w={"20%"} c="#393939BA" fw="600">
                  {historyItem.categoryName}
                </Text>
                <Text w={"20%"} c="#393939BA" fw="600">
                  {historyItem.platformName}
                </Text>
                <Text w={"20%"} c="#393939BA" fw="600">
                  {historyItem.deviceName}
                </Text>
                <Text w={"20%"} c="#393939BA" fw="600">
                  {historyItem.dateofbooking}
                </Text>
                <Text w={"20%"} c="#393939BA" fw="600">
                  {historyItem.dateOfReturn}
                </Text>
              </Flex>
            </Box>
          ))}
          <Flex justify="center" mt="lg">
            <Pagination
              page={currentPage}
              onChange={handlePageChange}
              total={totalPages}
              color="#182452"
            />
          </Flex>
        </ScrollArea>
      </Paper>
    </div>
  );
}
